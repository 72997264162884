<template>
  <div class="ClientProfileEdition" >

    <CModal
      :show.sync="isClientModified"
      :no-close-on-backdrop="true"
      title="Mise à jour de votre profil"
      size="lg"
      color="dark"
    >
      Votre profil a bien été mis à jour 👍
      <template #footer>
        <CButton @click="isClientModified = false" color="dark">Fermer</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isClientLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow>
      <CCol>
        <h1>Vos informations </h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <CForm v-on:submit.prevent="modifyClient()">

          <CRow class="mb-2">
            <CCol sm="2">
              Vous êtes
            </CCol>
            <CCol sm="10">
              <CInputRadioGroup
                :checked.sync="modifyClientIsFirm"
                :custom="true"
                :inline="true"
                :disabled="!client.is_active"
                :options='clientIsFirmArrayForm'
              />
            </CCol>
          </CRow>

          <div v-if="modifyClientIsFirm == 'true'">
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Nom"
                  v-model="modifyClientName" type="text"
                  maxlength="150"
                  :disabled="!client.is_active"
                  @input="$v.modifyClientName.$touch()"
                  :isValid="$v.modifyClientName.$dirty ? !$v.modifyClientName.$error : null"
                  invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
                >
                </CInput>
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Siren"
                  v-model="modifyClientSiren" type="text"
                  maxlength="9"
                  :disabled="!client.is_active"
                  @input="$v.modifyClientSiren.$touch()"
                  :isValid="$v.modifyClientSiren.$dirty ? !$v.modifyClientSiren.$error : null"
                  invalid-feedback="Le numéro de SIREN doit être composé de 9 chiffres"
                >
                </CInput>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6">
                <CInput
                  label="Représentant légal"
                  v-model="modifyClientRepresentantLegal" type="text"
                  maxlength="150"
                  :disabled="!client.is_active"
                  placeholder="Nom du représentant légal"
                  @input="$v.modifyClientRepresentantLegal.$touch()"
                  :isValid="$v.modifyClientRepresentantLegal.$dirty ? !$v.modifyClientRepresentantLegal.$error : null"
                  invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
                  >
                </CInput>
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Forme sociale"
                  v-model="modifyClientFormeSociale" type="text"
                  maxlength="150"
                  :disabled="!client.is_active"
                  placeholder="Forme sociale de l'entreprise"
                  @input="$v.modifyClientFormeSociale.$touch()"
                  :isValid="$v.modifyClientFormeSociale.$dirty ? !$v.modifyClientFormeSociale.$error : null"
                  invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
                  >
                </CInput>
              </CCol>
            </CRow>




          </div>

          <div v-if="modifyClientIsFirm =='false'">
            <CRow >
              <CCol sm="6">
                <CInput
                  label="Prénom"
                  autocomplete="eclerk-no-autocomplete-input"
                  v-model="modifyClientFirstName" type="text"
                  maxlength="150"
                  placeholder="Prénom"
                  @input="$v.modifyClientFirstName.$touch()"
                  :isValid="$v.modifyClientFirstName.$dirty ? !$v.modifyClientFirstName.$error : null"
                  invalid-feedback="Le prénom doit comporter entre 2 et 150 caractères"
                >
                </CInput>
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Nom"
                  autocomplete="eclerk-no-autocomplete-input"
                  v-model="modifyClientLastName" type="text"
                  maxlength="150"
                  placeholder="Nom"
                  @input="$v.modifyClientLastName.$touch()"
                  :isValid="$v.modifyClientLastName.$dirty ? !$v.modifyClientLastName.$error : null"
                  invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
                >
                </CInput>
              </CCol>
            </CRow>
          </div>



          <CRow>
            <CCol sm="6">
              <CInput
                label="Email (facultatif)"
                type="email"
                maxlength="150"
                :disabled="!client.is_active"
                placeholder="Email (facultatif)"
                v-model="modifyClientEmail"
                @input="$v.modifyClientEmail.$touch()"
                :isValid="$v.modifyClientEmail.$dirty ? !$v.modifyClientEmail.$error : null"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <div role="group" class="form-group">
                <label for="modifyClientVuePhoneNumber" class>
                  Numéro de téléphone (facultatif)
                </label>
                <vue-phone-number-input
                  id="modifyClientVuePhoneNumber"
                  name="modifyClientVuePhoneNumber"
                  v-model="modifyClientPhoneNumber"
                  :default-country-code='modifyClientPhoneNumberResult.countryCode'
                  :preferred-countries="['FR', 'BE', 'DE']"
                  valid-color='#28a745'
                  error-color='#dc3545'
                  :translations="{
                    countrySelectorLabel: 'Code pays',
                    countrySelectorError: 'Choisir un pays',
                    phoneNumberLabel: 'Numéro de téléphone',
                    example: 'Exemple :'
                  }"
                  @update='modifyClientPhoneNumberResult = $event'
                  :required='false'
                />
                <small class="text-danger" v-if="modifyClientPhoneNumberResult.isValid == false">Veuillez rentrer un numéro de téléphone valide</small>
              </div>
            </CCol>
          </CRow>

          <CRow class="mb-2">
            <CCol sm="3">
              Votre adresse est une adresse :
            </CCol>
            <CCol sm="9">
              <CInputRadioGroup
                :checked.sync="modifyClientIsDomiciliation"
                :custom="true"
                :inline="true"
                :options='clientIsDomiciliationArrayForm'
              />
            </CCol>
          </CRow>


          <form autocomplete="eclerk-no-autocomplete-input">
            <label for="eclerk-modify-client-address">Adresse</label>
            <vue-google-autocomplete
              autocomplete="eclerk-no-autocomplete-input"
              ref="eclerkModifyClientAddress"
              id="eclerk-modify-client-address"
              name="eclerk-modify-client-address"
              classname="form-control"
              maxlength="299"
              placeholder="Rechercher l'adresse"
              @placechanged="chooseGoogleMapsModifyClientAddress"
              @inputChange="modifyClientAddress = $event.newVal"
              :enable-geolocation='true'
            >
            </vue-google-autocomplete>
          </form>

          <hr>

          <CRow class="mb-3">
            <CCol sm="3">
              Le payeur est-il une autre personne que vous ?
            </CCol>
            <CCol sm="9">
              <CInputRadioGroup
                :checked.sync="modifyClientIsDifferentPayeur"
                :custom="true"
                :inline="true"
                :options='booleanArrayForm'
              />
            </CCol>
          </CRow>

          <div v-if="modifyClientIsDifferentPayeur == 'true'">

            <CRow class="mb-2">
              <CCol sm="2">
                Le payeur est
              </CCol>
              <CCol sm="10">
                <CInputRadioGroup
                  :checked.sync="modifyClientPayeurIsFirm"
                  :custom="true"
                  :inline="true"
                  :options='clientIsFirmArrayForm'
                />
              </CCol>
            </CRow>

            <CRow v-if="modifyClientPayeurIsFirm =='true'">
              <CCol sm="6">
                <CInput
                  label="Siren"
                  autocomplete="eclerk-no-autocomplete-input"
                  v-model="modifyClientPayeurSiren" type="text"
                  maxlength="9"
                  placeholder="XXXXXXXXX"
                  @input="$v.modifyClientPayeurSiren.$touch()"
                  :isValid="$v.modifyClientPayeurSiren.$dirty ? !$v.modifyClientPayeurSiren.$error : null"
                  invalid-feedback="Le numéro de SIREN doit être composé de 9 chiffres"
                >
                </CInput>
              </CCol>
              <CCol sm="6">
                <CInput
                label="Représentant légal"
                autocomplete="eclerk-no-autocomplete-input"
                v-model="modifyClientPayeurRepresentantLegal" type="text"
                maxlength="150"
                placeholder="Nom du représentant légal"
                @input="$v.modifyClientPayeurRepresentantLegal.$touch()"
                :isValid="$v.modifyClientPayeurRepresentantLegal.$dirty ? !$v.modifyClientPayeurRepresentantLegal.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
                >
                </CInput>
              </CCol>
            </CRow>

            <CInput
              label="Nom du payeur"
              autocomplete="eclerk-no-autocomplete-input"
              v-model="modifyClientPayeurName" type="text"
              maxlength="150"
              placeholder="Nom"
              @input="$v.modifyClientPayeurName.$touch()"
              :isValid="$v.modifyClientPayeurName.$dirty ? !$v.modifyClientPayeurName.$error : null"
              invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
            >
            </CInput>

            <form autocomplete="eclerk-no-autocomplete-input">
              <label for="eclerk-modify-payeur-client-address">Adresse du payeur</label>
              <vue-google-autocomplete
                autocomplete="eclerk-no-autocomplete-input"
                ref="eclerkModifyClientPayeurAddress"
                id="eclerk-modify-payeur-client-address"
                name="eclerk-modify-payeur-client-address"
                classname="form-control"
                maxlength="299"
                placeholder="Rechercher l'adresse"
                @placechanged="chooseGoogleMapsModifyClientPayeurAddress"
                @inputChange="modifyClientPayeurAddress = $event.newVal"
                :enable-geolocation='true'
              >
              </vue-google-autocomplete>
            </form>
          </div>

          <CRow  v-if="client.is_active" class="mt-4">
            <CCol>
              <CButton
                :color="modifyClientButtonStyle"
                shape="pill" block class="px-4"
                @click='modifyClient'
                :disabled="isInvalidModifyClient || modifyClientInProcess">
                <CSpinner size="sm" label="update client spinner" v-if="modifyClientInProcess"></CSpinner>
                {{ modifyClientButtonText }}
              </CButton>
            </CCol>
          </CRow>
          <CRow  v-else class="mt-4">
            <CCol class="text-center">
              <strong> Votre profil a été archivé</strong>
            </CCol>
          </CRow>
        </CForm>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, numeric, requiredIf, email} from 'vuelidate/lib/validators'
import { APIClientConnected } from '@/api/APIClientConnected'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import VueGoogleAutocomplete from 'vue-google-autocomplete'

const  apiClientConnected = new APIClientConnected()

export default {
  name: 'ClientProfileEdition',
  components: {
    Loading,
    VueGoogleAutocomplete
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      isClientLoading: false,
      client: {
        collaborateur: {
          id: '',
          full_name: ''
        },
        is_domiciliation: false,
        address: '',
        email: '',
        id: '',
        is_firm: true,
        name: '',
        first_name: '',
        last_name: '',
        phone_country_code: '',
        phone_number: '',
        representant_legal: '',
        siren: '',
        forme_sociale: '',
        is_different_payeur: false,
        is_payeur_firm: true,
        payeur_siren: '',
        payeur_representant_legal: '',
        payeur_name: '',
        payeur_address: '',
        user: null
      },

      // ---- Modify Client ---
      isClientModified: false,

      modifyClientIsFirm: 'false',
      modifyClientSiren: '',
      modifyClientFormeSociale: '',
      modifyClientRepresentantLegal: '',
      modifyClientName: '',
      modifyClientFirstName: '',
      modifyClientLastName: '',
      modifyClientEmail: '',
      modifyClientPhoneNumber: null,
      modifyClientPhoneNumberResult: {
        countryCode: "FR",
      },
      modifyClientIsDomiciliation: 'false',
      modifyClientAddress: '',

      modifyClientIsDifferentPayeur: 'true',
      modifyClientPayeurIsFirm: 'true',
      modifyClientPayeurSiren: '',
      modifyClientPayeurRepresentantLegal: '',
      modifyClientPayeurName: '',
      modifyClientPayeurAddress: '',

      clientIsFirmArrayForm: [
        { label: 'Une personne morale', value: 'true' },
        { label: "Une personne physique", value: 'false' },
      ],

      clientIsDomiciliationArrayForm: [
        { label: 'Privée', value: 'false' },
        { label: "De domiciliation", value: 'true' },
      ],

      booleanArrayForm: [
        { label: 'Oui', value: 'true' },
        { label: "Non", value: 'false' },
      ],

      modifyClientButtonText: 'Modifier votre profil',
      modifyClientButtonStyle: 'outline-dark',
      modifyClientInProcess: false,
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isInvalidModifyClient () {
      if (this.$v.modifyClientIsFirm.$invalid || this.$v.modifyClientSiren.$invalid || this.$v.modifyClientFormeSociale.$invalid ||
      this.$v.modifyClientName.$invalid || this.$v.modifyClientFirstName.$invalid || this.$v.modifyClientLastName.$invalid ||
      this.$v.modifyClientEmail.$invalid || this.$v.modifyClientAddress.$invalid ||
      this.$v.modifyClientPhoneNumberResult.$invalid || this.$v.modifyClientIsDifferentPayeur.$invalid || this.$v.modifyClientPayeurIsFirm.$invalid ||
      this.$v.modifyClientPayeurSiren.$invalid || this.$v.modifyClientPayeurRepresentantLegal.$invalid ||
      this.$v.modifyClientPayeurName.$invalid || this.$v.modifyClientPayeurAddress.$invalid ) {
        return true
      }
      return false
    },
  },
  validations: {
    modifyClientIsFirm: {
      required
    },
    modifyClientSiren: {
      minLength: minLength(9),
      maxLength: maxLength(14),
      numeric,
      required: requiredIf(function () {
        return this.modifyClientIsFirm == 'true'
      }),
    },
    modifyClientFormeSociale: {
      minLength: minLength(2),
      maxLength: maxLength(149),
    },
    modifyClientRepresentantLegal: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.modifyClientIsFirm == 'true'
      }),
    },
    modifyClientName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.modifyClientIsFirm == 'true'
      }),
    },
    modifyClientFirstName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.modifyClientIsFirm == 'false'
      }),
    },
    modifyClientLastName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.modifyClientIsFirm == 'false'
      }),
    },
    modifyClientEmail: {
      email,
      maxLength: maxLength(249),
      minLength: minLength(2)
    },
    modifyClientPhoneNumberResult: {
      countryCode: {
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      isValid: {
        isValid(value) {
          if (this.modifyClientPhoneNumber == null) {
            return true
          }
          else {
            return value
          }
        }
      }
    },
    modifyClientIsDomiciliation: {
      required
    },
    modifyClientAddress: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(300)
    },

    modifyClientIsDifferentPayeur: {
      required
    },
    modifyClientPayeurIsFirm: {
      required
    },
    modifyClientPayeurSiren: {
      minLength: minLength(9),
      maxLength: maxLength(14),
      numeric,
      required: requiredIf(function () {
        return this.modifyClientPayeurIsFirm =='true' && this.modifyClientIsDifferentPayeur == 'true'
      }),
    },
    modifyClientPayeurRepresentantLegal: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.modifyClientPayeurIsFirm == 'true' && this.modifyClientIsDifferentPayeur == 'true'
      }),
    },
    modifyClientPayeurName: {
      maxLength: maxLength(149),
      minLength: minLength(2),
      required: requiredIf(function () {
        return this.modifyClientIsDifferentPayeur == 'true'
      }),
    },
    modifyClientPayeurAddress: {
      minLength: minLength(2),
      maxLength: maxLength(300),
      required: requiredIf(function () {
        return this.modifyClientIsDifferentPayeur == 'true'
      }),
    },
  },

  created: function() {
    this.connectedClientGetProfile()
  },

  methods: {
    connectedClientGetProfile () {
      this.isClientLoading = true
      apiClientConnected.connectedClientGetProfile(this.token)
        .then((result) => {
          this.client = result.data
          this.modifyClientIsFirm = String(this.client.is_firm)
          this.modifyClientSiren = this.client.siren
          this.modifyClientFormeSociale = this.client.forme_sociale
          this.modifyClientRepresentantLegal = this.client.representant_legal
          this.modifyClientName = this.client.name
          this.modifyClientFirstName = this.client.first_name
          this.modifyClientLastName = this.client.last_name
          this.modifyClientEmail = this.client.email
          if (this.client.phone_number != '') {
            this.modifyClientPhoneNumberResult = {
              countryCode: this.client.phone_country_code,
              isValid: true,
              nationalNumber: this.client.phone_number
            }
            this.modifyClientPhoneNumber = this.client.phone_number
          }
          this.modifyClientIsDomiciliation = String(this.client.is_domiciliation)
          this.modifyClientAddress = this.client.address
          this.$refs.eclerkModifyClientAddress.update(this.client.address)


          this.modifyClientIsDifferentPayeur = String(this.client.is_different_payeur)
          this.modifyClientPayeurIsFirm = String(this.client.is_payeur_firm)
          this.modifyClientPayeurSiren = this.client.payeur_siren
          this.modifyClientPayeurRepresentantLegal = this.client.payeur_representant_legal
          this.modifyClientPayeurName = this.client.payeur_name
          this.modifyClientPayeurAddress = this.client.payeur_address

          if (this.modifyClientIsDifferentPayeur == 'true') {
            this.$refs.eclerkModifyClientPayeurAddress.update(this.client.payeur_address)
          }
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isClientLoading = false
        })
    },

    // ---------- Modify client -----------
    modifyClient () {
      this.modifyClientButtonText = 'Modification en cours'
      this.modifyClientButtonStyle = 'secondary'
      this.modifyClientInProcess = true
      apiClientConnected.connectedClientModifyProfile(this.token,
        this.modifyClientIsFirm, this.modifyClientSiren, this.modifyClientFormeSociale, this.modifyClientRepresentantLegal, this.modifyClientName,
        this.modifyClientFirstName, this.modifyClientLastName,
        this.modifyClientEmail, this.modifyClientPhoneNumberResult.countryCode, this.modifyClientPhoneNumberResult.nationalNumber,
        this.modifyClientIsDomiciliation, this.modifyClientAddress, this.modifyClientIsDifferentPayeur, this.modifyClientPayeurIsFirm,
        this.modifyClientPayeurSiren, this.modifyClientPayeurRepresentantLegal, this.modifyClientPayeurName, this.modifyClientPayeurAddress)
      .then(() => {
        this.connectedClientGetProfile()
        this.isClientModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.modifyClientButtonText = 'Modifier votre profil'
        this.modifyClientButtonStyle = 'outline-dark'
        this.modifyClientInProcess = false
      })
    },

    chooseGoogleMapsModifyClientAddress: function (addressData) {
      this.modifyClientAddress = addressData['street_number'] + ', ' + addressData['route'] + ', ' + addressData['locality'] + ', ' + addressData['country'];
    },

    chooseGoogleMapsModifyClientPayeurAddress: function (addressData) {
      this.modifyClientPayeurAddress = addressData['street_number'] + ', ' + addressData['route'] + ', ' + addressData['locality'] + ', ' + addressData['country'];
    },
  }
}
</script>
